<template>
	<div class="mainTem">
		<!-- 顶部提示导航 -->
		<!-- <div style="padding: 0 20px;">
      <top-bar :isFullStyle="true"></top-bar>
    </div> -->
		<el-container class="StorageCon">
			<el-header>
				<div class="SellerTopCon" style="display: flex;justify-content: space-between; width: 100%;">
					<!-- <div style=""> -->
						<div class="" style="display: flex;">
							<div class="titleNavLogo">
								<!-- <router-link :to="{name:'StorageHome'}" ></router-link> -->
								<a href="javascript:void(0)">
									<img src="../../assets/logo_en.png" width="150" height="auto" />
									<!-- <img :src="$RunConfig.logoSrc" width="150" height="auto" /> -->
									<!-- <img src="../../assets/logo_en.png" width="150" height="auto" v-if="'en' == $i18n.locale" /> -->
									<!-- <img src="../../assets/logo2.png" width="150" height="auto" v-else /> -->
								</a>
							</div>
							<div class="titleNavTit top-collapse" style="">
								<el-button type="" plain icon="el-icon-s-fold" @click="collapse=true" v-if="!collapse"></el-button>
								<el-button type="" plain icon="el-icon-s-unfold" @click="collapse=false" v-else></el-button>
							</div>
							<div class="titleNavTit">
								<!-- <el-popover placement="top" width="210" trigger="hover"> -->
									<!-- <div style="text-align: center; padding-bottom: 10px; font-weight: bold;">
										<img src="../../assets/images/wechat_kf/wechat_hytx_wms.jpg" width="200" height="200" />
										<div>互易仓(微信公众号)</div>
										<div>{{$t('i18nn_0f63cc67b9017a81')}}</div>
									</div> -->
									<a href="https://www.hytxlogistics.com/warehouse"  target="_blank">
										<div>
											{{ $t('Storage.common.title') }}
										</div>
									</a>
								<!-- </el-popover> -->
							</div>
							
							
							<div class="titleNavTit">
								<a href="https://www.hytxlogistics.com/" target="_blank">{{ $t('Storage.common.title2') }}</a>
							</div>
							<!-- <div class="titleNavTit"><a href="https://docs.qq.com/sheet/DWWJTeE5RTkp4ZlpN" target="_blank">{{$t('i18nn_866ffc98aa735561')}}</a></div> -->
							<!-- <div class="titleNavTit"><a href="https://www.hytxlogistics.com/contact" target="_blank">{{$t('i18nn_1318271bf151d3b1')}}</a></div> -->
							<!-- <div class="titleNavTit">
								<el-popover placement="top" width="210" trigger="hover">
									<div>
										<img src="../../assets/images/wechat_kf/wechat_kf.jpg" width="200" height="200" />
										<div style="text-align: center; padding-bottom: 10px; font-weight: bold;">{{$t('i18nn_c12ab070fe3cbeac')}}</div>
										<div>手机号：+86 15972134233</div>
										<div>微信号：L245727336</div>
									</div>
									<a href="javascript:void(0)" slot="reference">
										<div>{{$t('i18nn_eaf9e22fc96815be')}}</div>
									</a>
								</el-popover>
							</div> -->
							<!-- <div class="titleNavTit">
								<router-link :to="{ name: 'AmazonAuth' }" target="_blank">Amazon</router-link>
							</div> -->
						</div>
					<!-- </div> -->

					<div class="" style="width: 400px; color:#fff; display: flex;">
						<!-- <div class="titleNavTit"> -->
						<!-- <router-link :to="{name:'WarehouseSku'}" >{{$t('i18nn_129bddcc1b0afba9')}}</router-link> -->

						<!-- <el-dropdown :show-timeout="0">
                    <span class="el-dropdown-link">
                      <span class="nameLink" v-if="$store.getters.getUserInfo">{{ $store.getters.getUserInfo.account }}</span>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="topbarDropdown">
                      <a href="https://www.hytx.com" target="_blank"><el-dropdown-item>{{$t('i18nn_01d515661b01a0b3')}}</el-dropdown-item></a>
                      <a href="javascript:void(0)" @click="loginOutAction()"><el-dropdown-item>{{$t('i18nn_b4bced00356095dc')}}</el-dropdown-item></a>
                    </el-dropdown-menu>
                  </el-dropdown> -->

						<!-- <el-dropdown>
                    <span class="el-dropdown-link">{{$t('i18nn_738246ccd0ca0224')}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>{{$t('i18nn_2e35c13f129cf855')}}</el-dropdown-item>
                      <el-dropdown-item>{{$t('i18nn_d26ae04e63fe0940')}}</el-dropdown-item>
                      <el-dropdown-item>{{$t('i18nn_fec31d0353b1a1db')}}</el-dropdown-item>
                      <el-dropdown-item disabled>{{$t('i18nn_cff7518d379f060f')}}</el-dropdown-item>
                      <el-dropdown-item divided>{{$t('i18nn_0be9f3f483e1d46d')}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown> -->
						<!-- </div> -->
						<div class="topHeaderRi" style="width: 200px;">
							<!-- <span class="el-dropdown-link">
								<span class="nameLink">
									<a href="javascript:void(0)" @click="switchLang()">
										<span>
											<span v-if="'en' == $i18n.locale">{{ $t('topBar.Chinese') }}</span>
											<span v-else>{{ $t('topBar.English') }}</span>
											<i class="el-icon-location-outline el-icon--right"></i>
										</span>
									</a>
								</span>
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span> -->
							<el-dropdown :show-timeout="0">
								<div class="el-dropdown-link">
									<div class="nameLink">
										<span>
											<!-- <i class="el-icon-refresh"></i> -->
											{{ $t('topBar.language') }}: &nbsp;
										</span>
										<span v-if="'en' == $i18n.locale">{{ $t('topBar.English') }}</span>
										<span v-else>{{ $t('topBar.Chinese') }}</span>
										<i class="el-icon-arrow-down el-icon--right"></i>
									</div>
								</div>
								<el-dropdown-menu slot="dropdown" class="topbarDropdown">
									<!-- <a href="javascript:void(0)" @click="switchLang()">
										<el-dropdown-item>
											<span>
												<span v-if="'en' == $i18n.locale">{{ $t('topBar.Chinese') }}</span>
												<span v-else>{{ $t('topBar.English') }}</span>
												<i class="el-icon-location-outline el-icon--right"></i>
											</span>
										</el-dropdown-item>
									</a> -->
									<a href="javascript:void(0)" @click="switchLang('cn')">
										<el-dropdown-item>{{ $t('topBar.Chinese') }}</el-dropdown-item>
									</a>
									<a href="javascript:void(0)" @click="switchLang('en')">
										<el-dropdown-item>{{ $t('topBar.English') }}</el-dropdown-item>
									</a>
								</el-dropdown-menu>
							</el-dropdown>
						</div>

						<div class="topHeaderRi" style="width: 200px;">
							<el-dropdown :show-timeout="0" trigger="click">
								<div class="el-dropdown-link">
									<span class="nameLink" v-if="$store.getters.getUserInfo">
									{{ $store.getters.getUserInfo.mobile||$store.getters.getUserInfo.account }}
									</span>
									<i class="el-icon-arrow-down el-icon--right"></i>
								</div>
								<el-dropdown-menu slot="dropdown" class="topbarDropdown">
									<!-- <a href="javascript:void(0)" @click="switchLang()">
										<el-dropdown-item>
											<span>
												<span v-if="'en' == $i18n.locale">{{ $t('topBar.Chinese') }}</span>
												<span v-else>{{ $t('topBar.English') }}</span>
												<i class="el-icon-location-outline el-icon--right"></i>
											</span>
										</el-dropdown-item>
									</a> -->
									<!-- <router-link :to="{ name: 'forgotPassword' }">
										<el-dropdown-item>{{$t('i18nn_dae8d92a749abc4e')}}</el-dropdown-item>
									</router-link> -->
									<a href="javascript:void(0)" @click="openModifyPassword()">
										<el-dropdown-item>{{ $t('i18nn_dae8d92a749abc4e') }}</el-dropdown-item>
									</a>
									
									<a href="javascript:void(0)" @click="loginOut()">
										<el-dropdown-item>{{ $t('Storage.common.Logout') }}</el-dropdown-item>
									</a>
									<!-- <router-link :to="{ name: 'helpDoc' }" target="_blank">
										<el-dropdown-item>{{ $t('i18nn_54153ccc0c756e9f') }}</el-dropdown-item>
									</router-link> -->
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
				</div>

				<!-- <el-menu
          :default-active="activeIndex"
          class="sellerMainNav"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
          background-color="#091426"
          text-color="#fff"
          active-text-color="#FF8F15"
        >
          <el-menu-item :route="{name:'home'}" index="home" class="titleNav">
            <img src="../../assets/logo2.png" width="130" height="auto" />
          </el-menu-item>
          <el-menu-item :route="{name:'home'}" index="home">
            <span style="font-size: 16px;color:#fff;">
              {{$t("sellerCenter.common.title")}}
            </span>
          </el-menu-item>
        </el-menu> -->
			</el-header>

			<el-container :style="{ height: $store.state.frameConHeight + 'px' }">
				<el-aside style="width:auto;">
					<!-- <el-aside width="180px"> -->
					<el-menu
						:default-active="activeIndexSub"
						:default-openeds="defaultOpeneds"
						:unique-opened="true"
						:collapse="collapse"
						class="sellerSubNav"
						:background-color="'#2d333e'"
						:text-color="'#ffffff'"
						:active-text-color="'#409EFF'"
						:router="true"
						@open="handleOpen"
						@close="handleClose"
						v-loading="loading"
					>
						<el-menu-item  :route="{ name: 'Home' }" :index="'Home'">
						  <i class="el-icon-s-home"></i>
						  <span slot="title">{{$t('i18nn_f6e62f9642018cbb')}}</span>
						</el-menu-item>
						<el-submenu v-for="(item, index) in menusList" :key="index" :route="{ name: item.url }" :index="item.url" v-if="'1' == item.isShow">
							<template slot="title">
								<i :class="item.iconcls?item.iconcls:'el-icon-menu'"></i>
								<span slot="title">
									{{ 'en'==$i18n.locale?item.menuNameEn:item.menuName }}
								</span>
							</template>
						
							<el-menu-item v-for="(item2, index2) in item.subListMenuList" :key="index2" :route="{ name: item2.url }" :index="item2.url" v-if="'1' == item2.isShow">
								<i :class="item2.iconcls?item2.iconcls:'el-icon-document'"></i>
								{{ 'en'==$i18n.locale?item2.menuNameEn:item2.menuName }}
							</el-menu-item>
						</el-submenu>
						
					</el-menu>
				</el-aside>
				<!-- </el-aside> -->
				<!-- <el-main :style="{ overflow: $store.state.isScrollMain ? 'hidden' : '', padding: '0px' }"><router-view></router-view></el-main> -->
				<el-main :style="{ padding: '0px' }"><router-view></router-view></el-main>
			</el-container>
			<!-- <router-view></router-view> -->
		</el-container>
		
		<dialogModifyPassword :openTime="modifyPassOpenTime"></dialogModifyPassword>

		<!-- <AmazonAuthModal ref="AmazonAuthModal"></AmazonAuthModal> -->
	</div>
</template>

<script>
// import topBar from "../header/Topbar.vue";
// import mainSearch from '../header/MainSearch.vue'
// import mainNav from '../header/MainNav.vue'
// import footerMain from '../footer/FooterMain.vue'
import dialogModifyPassword from '@/components/StorageCenter/components/dialogModifyPassword.vue';
export default {
	// name: "SellerCenterFrame",
	components: { dialogModifyPassword },
	//meta信息seo用
	metaInfo() {
		return {
			title: this.$t('meta.main')
		};
	},
	watch: {
		$route(to, from) {
			this.activeIndexSub = this.$route.name;
		}
	},
	data() {
		return {
			collapse: false,
			// activeLang: localStorage.lang || 'cn',
			// activeIndex: this.$route.matched && this.$route.matched[1] ? this.$route.matched[1].name : this.$route.name,
			activeIndex: this.$route.name,
			// defaultOpeneds: ['1', '2', '3', '4', '5','11'],
			defaultOpeneds: ['20'],
			activeName: 'second',
			activeIndexSub: this.$route.name,
			// showDetailData: {},
			loading: false,
			menusList:[],
			
			modifyPassOpenTime:"",

			// isShowAuthModal: false

		};
	},

	created() {
		this.init();
	},
	////编译挂载前
	mounted() {
		// console.log('SellerCenterFrame.vue created');
	},

	methods: {
		init() {
			// console.log('init');
		
			//设置菜单
			console.log('this.$store.getters.getMenusInfo', this.$store.getters.getMenusInfo);
			// console.log('this.menusList', this.menusList);
			let menusList = this.$store.getters.getMenusInfo;
			if (menusList && menusList.length>0) {
				this.menusList = menusList;
			} else {
				let UserInfo = this.$store.getters.getUserInfo;
				this.getPremissMenu(UserInfo);
				// setTimeout(()=>{
				// 	this.menusList = this.$store.getters.getMenusInfo;
				// },500);
			}
			
			console.log('this.menusList', this.menusList);
	
		},
		//跳转
		toPageName(pageName) {
			this.$router.push({ name: pageName });
		},
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
			console.log('this.activeIndex', this.activeIndex);
			// if(this.$route.matched && this.$route.matched[1]){
			//   this.activeIndex = this.$route.matched[1].path;
			//       // console.log('activeIndex',this.activeIndex);
			// }
		},
		//打开修改密码
		openModifyPassword(){
			this.modifyPassOpenTime = new Date().getTime();
		},
		
		//获得用户权限菜单
		getPremissMenu(userInfo) {
			// console.log(this);
			// let that = this;
			this.loading = true;
			this.$http
				.get(this.$urlConfig.WhMenusQuery)
				.then(({
					data
				}) => {
					// console.log(data);
					this.loading = false;
					if ('200' === data.code) {
						console.log(this.$t('i18nn_48fea1870aa235fd'));
						// this.$router.push({name:'Frame'});
						this.loginSuccess(userInfo, data.rows);
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('i18nn_725d750832726cc3'));
					}
				})
				.catch(error => {
					console.log(error);
					this.loading = false;
					this.$message.error(this.$t('i18nn_747b33bfa850b075'));
				});
		},
		
		//登录成功
		loginSuccess(userinfo, whMenusArr) {
			// this.$store.dispatch('USER_SIGNOUT');
			// // console.log(this.$store.state.login.userInfo);
			// this.$store.dispatch('UPDATE_USERINFO', userinfo);
			this.$store.dispatch('UPDATE_MENUS', whMenusArr);
			
			this.menusList = this.$store.getters.getMenusInfo;
			// this.$store.commit('UPDATE_USERINFO',userinfo)
			// console.log(window.history);
			// console.log(this.$router);
			// return;
		
			// let UserInfo = this.$store.getters.getUserInfo;
			// if (UserInfo && UserInfo.id) {
			// 	// console.log('getUserInfo success', UserInfo);
			// 	// this.loginSuccessCallBack(UserInfo);
			// } else {
			// 	// this.$alert("获取登录信息失败，请重新登录", this.$t('tips.tipsTitle'), {
			// 	//     type:'warning',
			// 	//     // //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	// });
			// 	//如果第一次存不到缓存，执行第二次
			// 	// console.log('getUserInfo error111', UserInfo);
			// 	// let _this = this;
			// 	// this.$store.dispatch('USER_SIGNOUT');
			// 	// this.$store.dispatch('UPDATE_USERINFO', userinfo);
			// 	this.$store.dispatch('UPDATE_MENUS', whMenusArr);
			// 	// let UserInfo2 = this.$store.getters.getUserInfo;
			// 	// this.$store.commit('UPDATE_USERINFO',userinfo)
			// 	// if (UserInfo2 && UserInfo2.id) {
			// 	// 	this.loginSuccessCallBack(UserInfo2);
			// 	// } else {
			// 	// 	//第二次存不到 刷新浏览器
			// 	// 	//再次取不到刷新浏览器
			// 	// 	// console.log('getUserInfo error222', this.$store.getters.getUserInfo);
			// 	// 	window.location.reload();
			// 	// }
			// }
		},
		
		//打开授权弹窗
		// openAuthModal(){
		// 	// let routeData = this.$router.resolve({
		// 	//    name: "AmazonAuth",
		// 	// });
		// 	// window.open(routeData.href, '_top');

		// 	// this.isShowAuthModal = true;

		// 	this.$nextTick(()=>{
		// 		this.$refs.AmazonAuthModal.open();
		// 	})
		// },
		//登出
		// loginOut(){
		//   console.log("loginOut");
		//   this.$store.dispatch('USER_SIGNOUT');
		//   // console.log(this);
		//   // window.reload();
		//   console.log(this.$router);
		//   console.log(this.$route);
		//   //登出
		//   this.$http.post(this.$urlConfig.HyLoginOut,{}).then(response => {
		//     console.log(this.$t('i18nn_74708f3a8699305a'), response);
		//   }).catch(err => {
		//     console.error(err);
		//   });
		//   if('home'==this.$route.name){
		//     this.$router.go(0);
		//     // window.location.reload();
		//   } else {
		//     this.$router.replace({name:'home'});
		//     // window.location.reload();
		//   }

		//   // this.$router.go(0);

		//   // this.userInfo = this.$store.getters.getUserInfo;
		//   // this.role = this.$store.getters.getRole;
		//   // this.$location.reload();
		// },
		//切换语言
		switchLang(lang) {
			// let lang = 'en';
			// if ('cn' === this.$i18n.locale) {
			// 	lang = 'en';
			// } else {
			// 	lang = 'cn';
			// }
			this.$i18n.locale = lang;
			// this.activeLang = lang;
			// localStorage.setItem("lang",lang);
			this.$store.dispatch('UPDATE_LANG', lang);

			// this.$message.success("切换语言成功，建议重新刷新浏览器。");
			this.$message.success(this.$t('topBar.switchLangSuccess'));
			// window.location.reload();
			// this.$confirm(this.$t('topBar.switchLangSuccess'), this.$t('tips.tipsTitle'), {
			// 	// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	// //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			// 	type: 'warning'
			// })
			// 	.then(() => {
			// 		window.location.reload();
			// 	})
			// 	.catch(() => {});
			// console.log(localStorage.lang);
		},
		// testClick() {
		//   console.log(11);
		// },
		//登出
		loginOut() {
			console.log('loginOut');
			this.$store.dispatch('USER_SIGNOUT');
			console.log('dispatch1', this.$store.getters.getUserInfo);
			if (this.$store.getters.getUserInfo && this.$store.getters.getUserInfo.id) {
				this.$store.dispatch('USER_SIGNOUT');
				console.log('dispatch2', this.$store.getters.getUserInfo);
			}
			// console.log(this);
			// window.reload();
			console.log(this.$router);
			console.log(this.$route);
			//登出
			this.$http
				.post(this.$urlConfig.HyLoginOut, {})
				.then(response => {
					console.log(this.$t('i18nn_74708f3a8699305a'), response);
				})
				.catch(err => {
					console.error(err);
				});
			// return;
			// if ('WarehouseSku' == this.$route.name) {
			//   this.$router.go(0);
			//   // window.location.reload();
			// } else {
			this.$router.replace({
				name: 'login'
			});
			// window.location.reload();
			// }
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/**/


.StorageCon {
	.el-aside {
		// width:auto;
		background-color: #2d333e;
		border-right: 1px solid #e6e6e6;
		.el-menu-vertical-demo:not(.el-menu--collapse) {
		    width: 180px;
		    min-height: 400px;
		  }
		.el-menu {
			border-right: 0;
			background-color: #2d333e;

			.el-menu-item-group__title {
				// padding-left: 0 !important;
			}

			/deep/.el-submenu__title {
				height: 40px;
				line-height: 40px;
				font-size: 14px;
				// line-height: 12px;
				color: #ccc;
				min-width: 200px;
			}

			.el-menu-item {
				font-size: 14px;
				color: #fff;
				height: 40px;
				line-height: 40px;
				padding: 0 0 0 10px;
				// min-width: 160px;
				&.is-active {
					i {
						color: #2577e4;
					}
				}
				.el-submenu__title {
					height: 40px;
					line-height: 40px;
					font-size: 12px;
					// line-height: 12px;
					color: #ccc;
					// min-width: 180px;
				}
				span {
					// display: inline-block;

					// padding-left: 20px;
					// margin-left: 15px;
				}
				i {
					color: #fff;
				}
				&:hover,
				&:focus {
					background-color: #2577e4;
					color: #fff;
					i {
						// color: #fff;
						// color: #2577e4;
					}
				}
			}
			.el-menu-item.is-active {
				background-color: #2577e4;
				// color: #fff;
				// i {
				//   color: #fff;
				// }
			}

			// .el-menu-item:hover, .el-menu-item:focus {
			//   color: #2D333E;
			// }
		}
	}

	.el-header {
		padding: 0;
		.sellerMainNav {
			.el-menu-item {
				color: #aeafb0 !important;
				&:hover,
				&:focus {
					// background-color: #414957!important;
					color: #fff !important;
				}
			}
			.is-active {
				background-color: #414957 !important;
				border-bottom-color: #414957 !important;
				color: #fff !important;
			}
			.titleNav {
				text-align: center;
				width: 184px;
				font-size: 16px;
				color: #fff !important;
			}
			.userNav {
				float: right;
				.userNavHead {
					border-radius: 30px;
					overflow: hidden;
				}
				.userNavHeadLogo {
					max-width: 400px;
					overflow: hidden;
				}
			}
		}
	}
	.sellerSubNav {
		min-height: 600px;
	}
}

/*左边侧导航*/
.subNavTitle3 {
	display: block;
	// margin-top: 10px;
	// margin-left: 20px;
	// text-align: center;
	color: #ccc;
	font-size: 12px;
	line-height: 12px;
	padding: 0;
	padding-top: 5px;
	font-weight: 400;
}
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//   width: 200px;
//   min-height: 400px;
// }
// .subNavTitle {
//   display: block;
//   margin-top: 10px;
//   margin-right: 20px;
//   text-align: center;
//   color: #fff;
//   font-size: 16px;
// }
.SellerTopCon {
	// background-color: #405d79;
	background: #29435E;
	display: flex;
	width: 100%;
	height: 60px;
	.titleNavLogo {
		width: 184px;
		// height: 60px;
		margin-top: 14px;
		// padding-left: 10px;
		text-align: center;
		a {
			width: 150px;
			height: 100%;
			// background: url(../../assets/logo2.png) no-repeat center center;
			// background-size: 150px auto;

			display: inline-block;
			text-decoration: none;
			text-align: center;
		}
	}
	.titleNavTit {
		height: 60px;
		min-width: 100px;
		max-width: 150px;
		padding: 0 10px;
		text-align: center;
		overflow: hidden;//溢出隐藏
		white-space: nowrap;//不换行
		text-overflow: ellipsis;
		a {
			line-height: 60px;
			font-size: 16px;
			color: #fff;
			display: block;
			text-decoration: none;
		}
	}
	.top-collapse {
		padding-top:10px; 
		min-width:60px;
		.el-button {
			font-size:20px;
			padding:8px 12px;
		}
	}
}

// .topNavCon {
//   .topNavCon_left {

//     width: 200px;
//     margin-left: -100%;
//     float: left;
//   }
//   .topNavCon_center {
//       width: 100%;
//       float: left;
//       .topHeadNav {
//         margin-left: 200px;
//         margin-right: 150px;
//       }
//   }
.topNavCon_right {
	width: 150px;
	// margin-left: -150px;
	// float: right;
}
// }
.topHeaderRi {
	// text-align: right;
	// height: 60px;
	// padding-right: 20px;
	overflow: hidden;
	color: #fff;
	min-width: 120px;
	background-color: #29435e;
	margin-left: 1px;
	text-align: center;
	.el-dropdown {
		width: 100%;
		color: #fff;
		cursor: pointer;
		.el-dropdown-link {
			display: block;
			width: 100%;
			height: 100%;
			.nameLink {
				display: inline-block;
				vertical-align: middle;
				max-width: 150px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				line-height: 55px;
			}
			.el-icon-arrow-down {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}
/*顶部下拉*/
.topbarDropdown.el-dropdown-menu {
	// padding: 5px 0;
	// margin-top: 0px;
	background-color: #274c79;
	top: 48px !important;
	width: 150px;
	a {
		text-decoration: none;
		color: #fff;
		.el-dropdown-menu__item {
			color: #fff;
			&:hover {
				background-color: #206ce0;
			}
			// font-size: 12px;
			// line-height: 30px;
			// padding: 0 20px;
		}
	}
	&[x-placement^='bottom'] .popper__arrow::after {
		border-bottom-color: #274c79;
	}
	&[x-placement^='bottom'] .popper__arrow::after {
		border-bottom-color: #274c79;
	}
}
</style>
